/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { CONFIG } from './app-config.service';
import { LocalStorageService } from './core/local-storage.service';
import { GlobalVariable } from '../global';
import * as moment from 'moment';

// import { table } from 'console';
@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  // httpOptions: { headers: HttpHeaders ,};

  loginPath: string;
  path: string;

  constructor(
    private http: HttpClient,
    public globals: GlobalVariable,
    private localStorageService: LocalStorageService
  ) {
    if (this.globals.guestLogin != true) {
      const udid = this.localStorageService.getUdId('UdId');
      if (udid != null && udid != 'null') {
        this.globals.udid = udid;
      }
    }
   
    
  }
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }

  public customerLogin(email, password): Observable<any> {
    // this.loginURL.URL

    console.log(this.globals.BaseUrl + 'Customer_controller/login');
    const path = this.globals.BaseUrl + 'Customer_controller/login' + this.globals.getTimeZone();
    const data = JSON.stringify({
      email,
      password,
      business_id: CONFIG.business_id,
    });
    console.log(data);

    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  public customerSignUp(
    reCaptcha,
    firstname,
    lastname,
    email,
    password,
    phone,
    birthday?,
    anniversary?
  ): Observable<any> {
    // this.loginURL.URL

    // console.log(this.globals.BaseUrl+"Customer_controller/signup");
    const path = this.globals.BaseUrl + 'Customer_controller/signup' + this.globals.getTimeZone();
    const data = JSON.stringify({
      reCaptcha,
      firstname,
      lastname,
      email,
      password,
      phone,
      birthday,
      business_id: CONFIG.business_id,
    });
    console.log('signUp');
    console.log(data);

    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  public customerSignUpGiftcard(
    firstname,
    lastname,
    email,
    phone
  ): Observable<any> {
    // this.loginURL.URL

    // console.log(this.globals.BaseUrl+"Customer_controller/signup");
    const path = this.globals.BaseUrl + 'giftcard/create_user';
    const data = JSON.stringify({
      first_name: firstname,
      last_name: lastname,
      email,

      phone,

      business_id: CONFIG.business_id,
    });
    // console.log('signUp');
    console.log(data);

    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  customerForgotPassword(email): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/forgot_password' + this.globals.getTimeZone();
    const data = JSON.stringify({
      email,
      business_id: CONFIG.business_id,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  customerProfileEdit(
    FirstName,
    Email,
    LastName,
    Password,
    Phone,
    Birthday,
    Anniversary
  ): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/update_profile' + this.globals.getTimeZone();
    const data = JSON.stringify({
      // udid:this.localStorageService.getUdId('UdId'),
      firstname: FirstName,
      email: Email,
      lastname: LastName,
      password: Password,
      phone: Phone,
      birthday: Birthday,
      anniversary: Anniversary,
      business_id: CONFIG.business_id,
    });
    console.log('customerProfileEdit:', data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  getBusiness(cords?, branch?): Observable<any> {
    console.log('cords..', cords, this.globals.mycoordinates);
    let type;
    const path = this.globals.BaseUrl + 'Customer_controller/getPlaces' + this.globals.getTimeZone();
    if (this.globals.branch_enabled == 1 && !branch) {
      type = 'branches';
    } else {
      type = 'main';
    }
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      business_type: type,
      coordinates: cords,
      udid: this.globals.udid,
    });
    console.log('bsuines',CONFIG.business_id);
    
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  getBusinessBranches(cords?): Observable<any> {
    console.log('cords..', cords, this.globals.mycoordinates);
    let type;
    const path = this.globals.BaseUrl + 'Customer_controller/getPlaces' + this.globals.getTimeZone();
    type = 'branches';
    
    const data = JSON.stringify({
      business_id: this.globals.tempID,
      business_type: type,
      coordinates: cords,
      udid: this.globals.udid,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  
  getCategories(): Observable<any> {
    if (this.globals.order_time == 'schedule') {
    } else {
      const t = moment().format('MM/DD/YYYY hh:mm:ss a');
      this.globals.menuTime = t;
    }
    let platform;
    if (this.globals.inWeb == true) {
      platform = 'website';
    } else {
      platform = 'app';
    }
    const path = this.globals.BaseUrl + 'menu/app_categories_new' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
      platform,
      time: this.globals.menuTime,
    });

    console.log('getCategory',data);

    return this.http
      .post<any>(path, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  getItemDetails(id): Observable<any> {
    const path = this.globals.BaseUrl + 'menu/item_details' + this.globals.getTimeZone();
    const data = JSON.stringify({ item_id: id });
    return this.http
      .post<any>(path, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  getAddress(cords): Observable<any> {
    const path =
      this.globals.BaseUrl + 'Customer_controller/get_address_with_coordinates' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      coordinates: cords,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  // getUserLotteryRewards
  getUserLotteryRewards(cords): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/get_rewards' + this.globals.getTimeZone();
    const data = JSON.stringify({ udid: this.globals.udid, coordinates: cords });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  // CheckUserPoints
  CheckUserPoints(): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/check_points' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      b_id: CONFIG.business_id,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  // LoadBanners
  LoadBanners(): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/get_banners' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      business_id: CONFIG.business_id,
      app_version: this.globals.app_version,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  PaymentThroughPayOnVenue(instruction, amount): Observable<any> {
    const path = this.globals.BaseUrl + 'menu/place_order_r' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      payment_info: '',
      order_info: this.globals.itemsInCart,
      instruction,
      total: amount,
      payment_type: 'Pay_on_venue',
      reservation_id: this.globals.reservation_id,
    });
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  PaymentOnDelivery(
    reCaptcha,
    subtotal,
    Address,
    instruction,
    amount,
    order_date,
    Token,
    status
  ): Observable<any> {
    const cartItems = this.globals.itemsInCart;
    cartItems.forEach((orderItems) => {
      if (orderItems.menuExtrasSelected.length > 0) {
        orderItems.menuExtrasSelected.forEach((option) => {
          if (option.hasOwnProperty('options')) {
            option.optionNameSelected = option.options;
          }
        });
      }
    });

    if (
      this.globals.business_type == 'retail' ||
      this.globals.business_type == 'salon'
    ) {
      var path = this.globals.BaseUrl + 'retail/place_order_cash' + this.globals.getTimeZone();
    } else {
      var path = this.globals.BaseUrl + 'menu/place_order_cash' + this.globals.getTimeZone();
    }
    if (this.globals.guestLogin == true) {
      const phone = this.localStorageService.getPhone('Phone');
    }
    //send  pickup or delivery time to api as well
    let time;
    if (this.globals.OrderType == 'pickup') {
      time = this.globals.businessData.pickup_time;
    } else if (this.globals.OrderType == 'delivery') {
      time = this.globals.businessData.delivery_time;
    } else {
      time = '0';
    }
    const orderdata = JSON.stringify({
      reCaptcha,
      udid: this.globals.udid,
      payment_info: {
        address: Address,
        token: Token,
      },
      timeTaken: time, //added wed 16-aug 2023
      order_info: cartItems,
      instructions: instruction,
      total: amount,
      subtotal,
      scheduled_time: order_date,
      payment_type: status,
      business_id: CONFIG.business_id,
    });

    console.log('data', orderdata, reCaptcha);

    return this.http
      .post<any>(path, orderdata)
      .pipe(catchError(this.handleError));
  }

  LotteryRedeeem(StorePoint, special_flag): Observable<any> {
    const path =
      this.globals.BaseUrl + 'business_controller/app_lottery_redeem_orders' + this.globals.getTimeZone();
    const data = JSON.stringify({
      username: CONFIG.business_id,
      qr_code: this.globals.udid,
      amount: StorePoint,
      is_special: special_flag,
    });
    console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  guestLogin(email, first_name, last_name, phone): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/guest_login' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      email,
      first_name,
      last_name,
      phone,
    });
    console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  RedeemUserPoints(): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/redeem_points' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      b_id: this.globals.bussinessId,
      amount: this.globals.points_availed,
    });
    console.log('redeem points', data);
    console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  userBusinessAvailedDiscount(): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/customer_discount' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      b_id: this.globals.bussinessId,
      discount_count: this.globals.availed_discount_count,
    });

    console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  paymentCardInkiosk(apiParam: any): Observable<any> {
    const path = this.globals.BaseUrl + 'dejavoo/sale_request' + this.globals.getTimeZone();

    return this.http
      .post<any>(path, apiParam)
      .pipe(catchError(this.handleError));
  }

  placeOrderAfterDejavo(apiParam: any): Observable<any> {
    const path = this.globals.BaseUrl + 'menu/place_order_cash' + this.globals.getTimeZone();

    return this.http
      .post<any>(path, apiParam)
      .pipe(catchError(this.handleError));
  }

  printInvoice(apiParam: any): Observable<any> {
    const api = localStorage.getItem('printerId');
    const path = 'http://' + api + ':8080/api';

    return this.http
      .post<any>(path, apiParam)
      .pipe(catchError(this.handleError));
  }

  PaymentThroughStripeReservation(instruction, amount, Token): Observable<any> {
    const path = this.globals.BaseUrl + 'menu/place_order_r' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      payment_info: { address: '', token: Token },
      order_info: this.globals.itemsInCart,
      instruction,
      total: amount,
      payment_type: 'Stripe',
      reservation_id: this.globals.reservation_id,
    });

    console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  PaymentThroughStripe(
    reCaptcha,
    subTotal,
    Address,
    instruction,
    amount,
    order_date,
    Token,
    status,
    cash_discount,
    cardinfo?
  ): Observable<any> {
    const cartItems = this.globals.itemsInCart;
    cartItems.forEach((orderItems) => {
      if (orderItems.menuExtrasSelected.length > 0) {
        orderItems.menuExtrasSelected.forEach((option) => {
          console.log(option);
          if (option.hasOwnProperty('options')) {
            console.log('has property', option.hasOwnProperty('options'));
            option.optionNameSelected = option.options;
          }
        });
      }
    });

    const order_from_other = sessionStorage.getItem('orderFrom');
    if (
      this.globals.business_type == 'retail' ||
      this.globals.business_type == 'salon'
    ) {
      var path = this.globals.BaseUrl + 'retail/place_order' + this.globals.getTimeZone();
    } else {
      var path = this.globals.BaseUrl + 'menu/place_order' + this.globals.getTimeZone();
    }
    if (this.globals.guestLogin == true) {
      const phone = this.localStorageService.getPhone('Phone');
    }
    //send  pickup or delivery time to api as well
    let time;
    if (this.globals.OrderType == 'pickup') {
      time = this.globals.businessData.pickup_time;
    } else if (this.globals.OrderType == 'delivery') {
      time = this.globals.businessData.delivery_time;
    } else {
      time = '0';
    }
    console.log(orderdata, order_date);
    var orderdata = JSON.stringify({
      reCaptcha,
      udid: this.globals.udid,
      payment_info: {
        cash_discount,
        address: Address,
        token: Token,
        cardInfo: cardinfo,
        admin_stripe_enabled: this.globals.admin_stripe_enabled,
        authorize_enabled: this.globals.authorize_enabled,
      },
      order_info: cartItems,
      instruction,
      total: amount,
      subtotal: subTotal, //added 1-20-2022
      scheduled_time: order_date,
      payment_type: status,
      timeTaken: time, //added wed 16-aug 2023
      order_from_other: order_from_other ? order_from_other : null,
    });
    return this.http
      .post<any>(path, orderdata)
      .pipe(catchError(this.handleError));
  }
  
  GetUserOrderPlaced(): Observable<any> {
    if (
      this.globals.business_type == 'retail' ||
      this.globals.business_type == 'salon'
    ) {
      var link = this.globals.BaseUrl + 'retail/order_history' + this.globals.getTimeZone();
    } else {
      var link = this.globals.BaseUrl + 'menu/order_history' + this.globals.getTimeZone();
    }

    let data: any = {
      udid: this.globals.udid,
      business_id: CONFIG.business_id,
    };

    if (this.globals.orderOnCall) {
      data.call_order = 1;
    }

    data = JSON.stringify(data);

    console.log('orderOnCallorderOnCall');
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  getPoints(coordinates?): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/get_points' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_username: CONFIG.business_username,
      udid: this.globals.udid,
      coordinates,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  getPunches(coordinates): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/get_punchcards' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_username: CONFIG.business_username,
      udid: this.globals.udid,
      coordinates,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  getUserLotteryRewardsNew(coordinates): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/get_rewards_new' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      coordinates,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  getpunches_menuitems(): Observable<any> {
    const path = this.globals.BaseUrl + 'rewards/get_punches_rewards' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  reward_notification(): Observable<any> {
    const path = this.globals.BaseUrl + 'Customer_controller/get_welcome_modal' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(path, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  get_all_Rewards_new(): Observable<any> {
    const link = this.globals.BaseUrl + 'rewards/get_customer_rewards' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  getrewards_menuitems(): Observable<any> {
    const link = this.globals.BaseUrl + 'rewards/get_reward_list' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  getpoints_menuitems(): Observable<any> {
    const link = this.globals.BaseUrl + 'rewards/get_point_rewards' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  GetPunchcards(coordinates?): Observable<any> {
    const link = this.globals.BaseUrl + 'Customer_controller/get_punchcards' + this.globals.getTimeZone();
    const data = JSON.stringify({
      udid: this.globals.udid,
      coordinates,
      business_username: CONFIG.business_username,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  redeem_point_menu_reward(reward_id, reward_type) {
    const link = this.globals.BaseUrl + 'rewards/get_redeem_points' + this.globals.getTimeZone();

    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
      item_id: reward_id,
      reward_type,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  special_offer_banners() {
    const link =
      this.globals.BaseUrl + 'business_controller/get_special_offer_banners' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  getReviews() {
    const link = this.globals.BaseUrl + 'Customer_controller/getbusinessinfo' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_username: CONFIG.business_username });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  AddUserReview(user_email, description, rating) {
    const link = this.globals.BaseUrl + 'Customer_controller/review' + this.globals.getTimeZone();

    const data = JSON.stringify({
      useremail: user_email,
      title: '',
      description,
      rating,
      business: CONFIG.business_username,
    });
    console.log(data);
    // console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  date_convert(date) {
    const link = this.globals.BaseUrl + '/menu/get_date_convert' + this.globals.getTimeZone();
    const data = JSON.stringify({ date });

    console.log(data);

    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  booking_history() {
    const link = this.globals.BaseUrl + 'salon/booking_history' + this.globals.getTimeZone();
    console.log(this.globals.bussinessId);
    const data = JSON.stringify({
      udid: this.globals.udid,
      business_id: CONFIG.business_id,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  get_services_categories() {
    const link = this.globals.BaseUrl + 'salon/serviceCategories' + this.globals.getTimeZone();

    const data = JSON.stringify({ business_id: CONFIG.business_id });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  get_services(category_id) {
    const link = this.globals.BaseUrl + 'salon/get_services' + this.globals.getTimeZone();

    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      category_id,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  get_stylist(service_id) {
    const link = this.globals.BaseUrl + 'salon/stylistlist' + this.globals.getTimeZone();

    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      service_id,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  get_slots(service_id, stylist_id, scedule_time) {
    const link = this.globals.BaseUrl + 'salon/get_stylist_timing' + this.globals.getTimeZone();
    console.log('Time: ', scedule_time);
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      service_id,
      stylist_id,
      schedule_time: scedule_time,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  getPickupslots(id, time, type): Observable<any> {
    const link = this.globals.BaseUrl + 'events/businessSlots' + this.globals.getTimeZone();
    let data;
    data = JSON.stringify({
      // user_timezone_set: this.timeZone,
      business_id: id,
      schedule_time: time,
      type,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  booking_salon(
    service_id,
    stylist_id,
    time_slot,
    p_detail,
    amount_data,
    notes
  ) {
    const link = this.globals.BaseUrl + 'salon/booking' + this.globals.getTimeZone();

    const data = JSON.stringify({
      udid: this.globals.udid,
      business_id: CONFIG.business_id,
      service_id,
      stylist_id,
      time_slot,
      p_details: p_detail,
      raw_data: amount_data,
      notes,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  my_gift_cards() {
    const link = this.globals.BaseUrl + 'giftcard/get_customer_giftcards' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: this.globals.tempID,
      udid: this.globals.udid,
    }); //tempid is main branch id
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  booking_cancel(bookingId) {
    const link = this.globals.BaseUrl + 'salon/cancel_booking' + this.globals.getTimeZone();

    const data = JSON.stringify({
      id: bookingId,
      business_id: CONFIG.business_id,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  my_gift_cards_design() {
    const link = this.globals.BaseUrl + 'giftcard/get_giftcard_designs' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id }); //this is main branch's id... getting from app comp load
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  gift_cards() {
    const link = this.globals.BaseUrl + 'giftcard/get_business_giftcards' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: this.globals.tempID });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  public buyGiftCardByCreditCard(
    reCaptcha,
    token,
    id,
    design_id,
    amount,
    message,
    receieverUDID,
    ccfee,
    cardinfo?
  ): Observable<any> {
    const data = JSON.stringify({
      reCaptcha,
      udid_r: receieverUDID,
      ccFee: ccfee,
      business_id: this.globals.tempID,
      card_info: cardinfo,
      udid_s: this.globals.udid,
      token,
      giftcard_id: id,
      design_id,
      amount,
      message,
    });
    const link = this.globals.BaseUrl + 'giftcard/buy_giftcard' + this.globals.getTimeZone();
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(0), catchError(this.handleError));
  }
  public buyGiftCardByCash(id, design_id, amount, message): Observable<any> {
    const data = JSON.stringify({
      udid_r: this.globals.udid,
      business_id: CONFIG.business_id,
      udid_s: this.globals.udid,
      giftcard_id: '',
      design_id,
      amount,
      message,
    });
    const link = this.globals.BaseUrl + 'giftcard/buy_giftcard_cash' + this.globals.getTimeZone();
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  gallery_list() {
    const link = this.globals.BaseUrl + 'events/get_gallery_list' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  GetWalletCategories() {
    const link =
      this.globals.BaseUrl + 'Customer_controller/get_wallet_categories' + this.globals.getTimeZone();
    return this.http
      .get<any>(link)
      .pipe(retry(2), catchError(this.handleError));
  }
  privacyPolicy(type) {
    const link =
      this.globals.BaseUrl + 'Landingpage_controller/business_policies' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id, type });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  search_user(email) {
    const link = this.globals.BaseUrl + 'giftcard/search_user' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      email,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  welcomeBanners() {
    const link = this.globals.BaseUrl + 'Customer_controller/get_welcome_screen' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  verifyCoupon(coupon) {
    const link = this.globals.BaseUrl + 'customer_controller/verifyCoupon' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
      coupon,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  welcomeSlides() {
    const link = this.globals.BaseUrl + 'customer_controller/get_welcome_banners' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  rewardsSlides() {
    const link = this.globals.BaseUrl + 'rewards/get_reward_banners' + this.globals.getTimeZone();
    const data = JSON.stringify({ business_id: CONFIG.business_id });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }
  deleteProfile() {
    const link =
      this.globals.BaseUrl + 'customer_controller/deleteCustomerProfile' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  getHostInfo() {
    const link =
    this.globals.BaseUrl + 'customer_controller/get_website_business_info' + this.globals.getTimeZone();
    const url = window.location.hostname;
    const formData = new FormData();
    console.log(formData);
    formData.append('host', url);
    return this.http
      .post<any>(link, formData)
      .pipe(retry(2), catchError(this.handleError));
  }

  getOrderStatus(orderID) {
    const link = this.globals.BaseUrl + 'driver_controller/getOrderStatus' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      udid: this.globals.udid,
      order_id: orderID,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  getSocials(url) {
    const link =
      this.globals.BaseUrl + 'landingpage_controller/landing_page_data' + this.globals.getTimeZone();
    const data = JSON.stringify({
      host: url,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  submitContactMessage(form: any) {
    const link = this.globals.BaseUrl + 'business_controller/contact_us' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: this.globals.businessData.business_id,
      email: form.email,
      message: form.message,
      name: form.name,
      subject: form.subject,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  submitReservation(form) {
    const link = this.globals.BaseUrl + 'Reservations/makeOnlineReservation' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: this.globals.businessData.business_id,
      udid: this.globals.udid,
      customer_name: form.firstname + ' ' + form.lastname,
      customer_phone: form.phone,
      reservation_time: form.date + ' ' + form.time,
      persons: form.persons,
      email: form.email,
      event_type: form.occasion,
      instructions: form.instructions,
    });
    console.log(data);
    return this.http.post<any>(link, data).pipe(catchError(this.handleError));
  }
  getReservations() {
    const link = this.globals.BaseUrl + 'Reservations/getReservations' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: this.globals.businessData.business_id,
      udid: this.globals.udid,
      platform: 'app',
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  getRooms() {
    const link = this.globals.BaseUrl + 'Menu/get_hold_orders_all' + this.globals.getTimeZone();
    const data = JSON.stringify({
      id: this.globals.businessData.business_id,
      order_via: 'all',
      usertype: 'business',
      offset: '0',
      order_type: 'pending',
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  checkTableStatus(table_id: any) {
    const link = this.globals.BaseUrl + '/Table/checkTable' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: this.globals.businessData.business_id,
      table_id,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  holdOrder(
    subtotal,
    instruction,
    amount,
    order_date,
    Token,
    s_table,
    room
  ): Observable<any> {
    const cartItems = this.globals.itemsInCart;
    cartItems.forEach((orderItems) => {
      if (orderItems.menuExtrasSelected.length > 0) {
        orderItems.menuExtrasSelected.forEach((option) => {
          console.log(option);
          if (option.hasOwnProperty('options')) {
            console.log('has property', option.hasOwnProperty('options'));
            option.optionNameSelected = option.options;
          }
        });
      }
    });

    const path = this.globals.BaseUrl + 'Menu/hold_order' + this.globals.getTimeZone();

    //  if(!s_table){
    //    s_table.id=this.globals.dineInTable

    //  }
    // console.log('cart items .... ',this.globals.itemsInCart)
    const orderdata = JSON.stringify({
      username: '0',
      udid: this.globals.udid,
      business_id: CONFIG.business_id,
      platform: 'app',
      data: [
        {
          order_type: 'dine_in',
          cart_array: cartItems,
          instructions: instruction,
          total: amount,
          subtotal,
          scheduled_time: order_date,
          payment_type: 'dine_in',
          table_id: s_table?.id ? s_table.id : this.globals.dineInTable,
          room_id: room?.id,
          room_name: room?.name,
          table_capacity: s_table?.capacity,
          table_name: s_table?.title,
        },
      ],
    });

    console.log(orderdata);
    return this.http
      .post<any>(path, orderdata)
      .pipe(catchError(this.handleError));
  }
  getHoldOrders() {
    const link = this.globals.BaseUrl + '/customer_controller/getHoldOrder' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: this.globals.businessData.business_id,
      udid: this.globals.udid,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }

  dineInCalltoPOS(type, msg) {
    const link = this.globals.BaseUrl + '/customer_controller/dineInRequest' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: this.globals.businessData.business_id,
      udid: this.globals.udid,
      type,
      msg,
    });
    console.log(data);
    return this.http
      .post<any>(link, data)
      .pipe(retry(1), catchError(this.handleError));
  }
  getTipPercentages(): Observable<any> {
    const link = this.globals.BaseUrl + '/business/getBusinessTips' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(2), catchError(this.handleError));
  }

  checkPhoneOrEmail(email, phone) {
    const link =
      this.globals.BaseUrl + '/Customer_controller/check_customer_data' + this.globals.getTimeZone();

    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      email,
      phone,
    });
    return this.http
      .post<any>(link, data)
      .pipe(retry(0), catchError(this.handleError));
  }

  signup_(email, phone, name) {
    const link = this.globals.BaseUrl + '/Customer_controller/signup_with_phone' + this.globals.getTimeZone();
    const data = JSON.stringify({
      business_id: CONFIG.business_id,
      email,
      phone,
      name,
    });

    return this.http
      .post<any>(link, data)
      .pipe(retry(0), catchError(this.handleError));
  }
}
