import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {ResModelComponent} from '../components/res-model/res-model.component';
import { CartComponent } from './cart/cart.component';
import { SavedCardComponent } from './saved-card/saved-card.component';
import { HoldOrderComponent } from './hold-order/hold-order.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ResModelComponent,CartComponent,HoldOrderComponent,SavedCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // ResModelComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ResModelComponent,CartComponent,HoldOrderComponent,SavedCardComponent]
})
export class ComponentsModule { }
