/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prefer-const */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from 'src/app/services/core/local-storage.service';

@Component({
  selector: 'app-saved-card',
  templateUrl: './saved-card.component.html',
  styleUrls: ['./saved-card.component.scss'],
})
export class SavedCardComponent implements OnInit {
  @Input() type;
  paymentMethods = [];
  selectedYear: string;
  constructor(
    private localStorageService: LocalStorageService,
    private modalController: ModalController) {
  }

  ngOnInit() {
    this.getSavedCards();
  }
  close() {
    this.modalController.dismiss();
  }
  getSavedCards() {
    let tempArray = [];
    let response = localStorage.getItem('paymentmethods');
    tempArray = JSON.parse(response);
    if (tempArray != null) {
      this.paymentMethods = tempArray;
    }
  }

  maskNumber(cardNumber: string): string {
    if (cardNumber.length <= 8) return cardNumber;
    const visibleStart = cardNumber.slice(0, 4);
    const visibleEnd = cardNumber.slice(-4);
    const maskedPart = '*'.repeat(cardNumber.length - 8);
    return `${visibleStart}${maskedPart}${visibleEnd}`;
  }

  selectMethod(cardInfo: any) {
    this.modalController.dismiss({
      dismissed: true,
      data: cardInfo
    });
  }

  onYearChange(event) {
    this.selectedYear = event.detail.value;
  }
}
